<template>
<div>
    <main-layout></main-layout>
    <section class="wrapper">
        <div class="container-purchase-promo" v-if="promotionInfo.name">
            <b-card-img class="image-promo" :src="imageSource" alt="Promotion image" @error="setErrorImg"></b-card-img>
            <div class="promo-name">
                <h3>{{ promotionInfo.name }}</h3>
                <p>{{ promotionInfo.summary }}</p>
            </div>
            <div class="promo-date">
                <div class="from">
                    <p>{{ promotionInfo.expired_date_from | regularDateTime }}</p>
                </div>
                <div class="dash"></div>
                <div class="to">
                    <p>{{ promotionInfo.expired_date_to | regularDateTime }}</p>
                </div>
            </div>
            <div class="promo-condition">
                    <div class="discount">
                        <h5>{{ $t("promotion.discountAmount") }}</h5>
                        <p>{{ formatDiscountUnit(promotionInfo.pmt_type_detail, promotionInfo.pmt_type) }}</p>
                    </div>
                    <div class="vertical-line"></div>
                    <div class="minimum-amount">
                        <h5>{{ $t("promotion.minimumSubtotal") }}</h5>
                        <p>{{ this.$options.filters.formatCurrency(promotionInfo.minimum_total) || 0 }}</p>
                    </div>
                    <div class="limit">
                        <h5>{{ $t("promotion.maximumDiscount") }}</h5>
                        <p>{{ maximumDiscount }}</p>
                </div>
            </div>
            <p class="notice"><i>{{ '* Discounts are applied to subtotal before calculating VAT.' }}</i></p>
            <div class="line-dash-light"></div>
            <div class="promo-info">
                <!-- Promotion Detail -->
                <div class="item">
                    <div class="title">
                        <i class="fas fa-tags"></i>
                        <h3>{{ $t("promotion.promotionDetail") }}</h3>
                    </div>
                    <div class="info-detail">
                        <p>{{ promotionInfo.detail }}</p>
                    </div>
                    <!-- Display additional promotion info if available -->
                    <div class="info-detail mt-4">
                        <!-- Quantity -->
                        <div class="other">
                            <h5>{{ $t("promotion.quantity") + ': ' }}</h5>
                            <p>{{ promotionInfo.quantity }}</p>
                        </div>
                        <!-- Remaining -->
                        <div class="other">
                            <h5>{{ $t("promotion.remaining") + ': ' }}</h5>
                            <p>{{ promotionInfo.remaining }}</p>
                        </div>
                        <!-- Limit on Customer -->
                        <div class="other">
                            <h5>{{ $t("promotion.limitOnCustomer") + ': ' }}</h5>
                            <p>{{ promotionInfo.limit_on_customer }}</p>
                        </div>
                        <!-- Limit per Day -->
                        <div class="other">
                            <h5>{{ $t("promotion.limitPerday") + ': ' }}</h5>
                            <p>{{ promotionInfo.limit_per_day }}</p>
                        </div>
                    </div>
                    <!-- Display condition list if available -->
                    <div class="info mt-5" v-if="hasCondition">
                        <div class="other">
                            <i class="fas fa-cart-arrow-down"></i>
                            <h4>{{ $t("promotion.conditionList") + ': ' }}</h4>
                        </div>
                        <p class="mt-2">{{ conditionList }}</p>
                    </div>
                    <!-- Display apply target list if available -->
                    <div class="info mt-5" v-if="hasApplyTarget">
                        <div class="other">
                            <i class="fa fa-check-circle"></i>
                            <h4>{{ $t("promotion.targetList") + ': ' }}</h4>
                        </div>
                        <p class="mt-2">{{ targetList }}</p>
                    </div>
                </div>
                <!-- Store Apply -->
                <div class="item">
                    <div class="title">
                        <i class="fas fa-store"></i>
                        <h3>{{ $t("promotion.storeApply") }}</h3>
                    </div>
                    <div>
                        <ul>
                            <!-- Render each store in the promotion_stores array -->
                            <li v-bind:key="'promo-store-' + index" v-for="(store, index) in promotionInfo.promotion_stores">{{ store.name }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="promo-redeem">
                <div class="point">
                    <div class="yours">
                        <h5>{{ $t("promotion.yourPoints") }}</h5>
                        <p :class="pointStyle">{{ customerInfo.availablePoints }}</p>
                    </div>
                    <div class="require">
                        <h5>{{ $t("promotion.requiredPoints") }}</h5>
                        <p>{{ promotionInfo.point }}</p>
                    </div>
                </div>
                <div class="buttons">
                        <button class="button--secondary" @click="back">{{ $t("button.back") }}</button>
                 
                        <button class="button--redeem button--outline" @click="redeem">
                            <i class="fas fa-coins"></i>
                            <span>
                                <p>{{ $t('button.redeemPromotion') }}</p>
                            </span>
                        </button>
                    </div>
            </div>
        </div>
        <loader v-show="loading"></loader>
    </section>
</div>
</template>

<script>
import MainLayout from "@/layout/MainLayout.vue";
import Constants from '@/common/constant';
import {
    mapGetters,
    mapState
} from "vuex";
import Loader from "@/components/Loader.vue";
export default {
    name: "RedeemPromotion",
    components: {
        MainLayout,
        Loader,
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            loading: false,
        };
    },

    computed: {
        ...mapState(['promotion']),
        ...mapGetters({
            promotionInfo: "promotion/promotionInfo",
            authDataGet: "auth/authDataGet",
            customerInfo: "customer/customerInfo"
        }),
        pointStyle: function () {
            if (parseInt(this.customerInfo.availablePoints) < this.promotionInfo.point) {
                return 'point-lack';
            }

            return 'point-satisfy';
        },

        imageSource: function () {
            if (this.promotionInfo ?.image ?.full_original_url) {
                return this.promotionInfo.image.full_original_url;
            }

            return Constants.TEMP_IMAGE;
        },

        maximumDiscount: function () {
            if (parseInt(this.promotionInfo.limit) > 0) {
                return this.$options.filters.formatCurrency(this.promotionInfo.limit);
            }

            return this.$t("promotion.unlimited");
        },

        hasCondition: function () {
            return this.promotionInfo.item_by_item;
        },

        hasApplyTarget: function () {
            return this.promotionInfo.apply_items ?.selectedItems ?.length > 0;
        },

        conditionList: function () {
            return this.promotionInfo.condition_items.selectedItems.map(x => x.menunm).join(", ");
        },

        targetList: function () {
            return this.promotionInfo.apply_items.selectedItems.map(x => x.menunm).join(", ");
        }
    },
    async created() {
        this.loading = true;
        this.promotionId = this.$route.params.promotionId;
        console.log();
        await this.$store.dispatch("promotion/getPromotion", this.promotionId);
        this.loading = false;
    },
    //  beforeRouteEnter( ) {
    //     window.scrollTo(0, 0);
    // },
    async mounted() {
      this.loading = true;
    //   await this.$store.dispatch('promotion/fetchAllPromotion');
      await this.$store.dispatch('customer/fetchCustomerData', { id: this.authDataGet.id, brandInfo: this.brandInfo });
      this.loading = false;
    },
    methods: {
        formatDiscountUnit(value, type) {
            if (type === 'percent_discount')
                return `${value}%`

            return this.$options.filters.formatCurrency(value);
        },

        async redeem() {
            // if (this.customerInfo.availablePoints < this.promotionInfo.point) {
            //     this.$bvModal.msgBoxOk(this.$t("commons.notEnoughPoints"));
            //     return;
            // };

            this.loading = true;
            const promotion = {
                promotion: this.promotionInfo,
                user: this.authDataGet
            }

            await this.$store.dispatch("promotion/redeem", promotion);
            this.loading = false;
            if (this.promotion.error) {
                this.$bvModal.msgBoxOk(this.promotion.error, this.messageBoxOK);
                return;
            }

            this.$store.dispatch("customer/fetchCustomerData", {
                id: this.authDataGet.id,
                brandInfo: this.brandInfo
            });
            return this.$bvModal.msgBoxOk("Thank you! Promotion has been redeemed successfully!");
        },

        back() {
            this.$router.back();
        },
    },
};
</script>

  
<style lang="scss" scoped>
  </style>
