import { render, staticRenderFns } from "./RedeemPromotionDetail.vue?vue&type=template&id=e4bdebca&scoped=true&"
import script from "./RedeemPromotionDetail.vue?vue&type=script&lang=js&"
export * from "./RedeemPromotionDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4bdebca",
  null
  
)

export default component.exports